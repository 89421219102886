import {
  AccountBalanceWalletOutlined,
  AssignmentIndOutlined,
  BeachAccessOutlined,
  BlockOutlined,
  FormatListBulleted,
  GraphicEq,
  GroupsOutlined,
  HowToRegOutlined,
  Inventory2Outlined,
  LocationCityOutlined,
  LockPerson,
  MenuBookOutlined,
  PaletteOutlined,
  PeopleOutline,
  PointOfSaleOutlined,
  SchoolOutlined,
  WorkOutline,
  SignalCellularAlt,
} from '@mui/icons-material';
import { hasAnyPermissions } from 'app/utils/appHelpers';
import { PERMISSIONS } from 'app/utils/constants/permissions';

const menus = [
  {
    label: 'sidebar.menu.home',
    type: 'section',
    children: [
      {
        uri: '/dashboards/analytics',
        label: 'Analytics',
        type: 'nav-item',
        key: 'home',
        icon: <GraphicEq sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/dashboards/accounts',
        label: 'Accounts',
        type: 'nav-item',
        key: 'home',
        icon: <AccountBalanceWalletOutlined sx={{ fontSize: 20 }} />,
      },
    ],
  },
  {
    label: 'sidebar.menu.apps',
    type: 'section',
    children: [
      hasAnyPermissions([PERMISSIONS.ROLES]) && {
        uri: '/app/roles',
        label: 'Roles & Permissions',
        type: 'nav-item',
        key: PERMISSIONS.ROLES,
        icon: <LockPerson sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/app/accounts/all',
        label: 'Accounts',
        type: 'nav-item',
        key: [
          PERMISSIONS.BILL,
          PERMISSIONS.EXPENSE,
          PERMISSIONS.EXPENSE_CATEGORY,
        ],
        icon: <AccountBalanceWalletOutlined sx={{ fontSize: 20 }} />,
      },
      hasAnyPermissions([PERMISSIONS.BRANCH]) && {
        uri: '/app/branches',
        label: 'Branches',
        type: 'nav-item',
        key: PERMISSIONS.BRANCH,
        icon: <FormatListBulleted sx={{ fontSize: 20 }} />,
      },
      hasAnyPermissions([
        PERMISSIONS.EXAM_INITIATOR,
        PERMISSIONS.EXAM_TASK_FOLLOWUP,
      ]) && {
        label: 'Examination',
        type: 'collapsible',
        icon: <MenuBookOutlined />,
        children: [
          {
            uri: '/app/exam-init/all',
            label: 'Initiator',
            type: 'nav-item',
            key: PERMISSIONS.EXAM_INITIATOR,
          },
          {
            uri: '/app/exam-follow/all',
            label: 'FollowUp',
            type: 'nav-item',
            key: PERMISSIONS.EXAM_TASK_FOLLOWUP,
          },
        ],
      },

      hasAnyPermissions([PERMISSIONS.CLASS, PERMISSIONS.CLASS_SECTION]) && {
        uri: '/app/classes/all',
        label: 'Classes',
        type: 'nav-item',
        icon: <SchoolOutlined sx={{ fontSize: 20 }} />,
      },
      {
        label: 'Subjects',
        type: 'nav-item',
        uri: '/app/subjects',
        key: PERMISSIONS.CLASS_SECTION_SUBJECTS,
        icon: <MenuBookOutlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/app/users/all',
        label: 'Users',
        type: 'nav-item',
        key: 'user',
        icon: <PeopleOutline sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/app/students/all',
        label: 'Students',
        type: 'nav-item',
        key: 'student',
        icon: <GroupsOutlined sx={{ fontSize: 20 }} />,
      },
      hasAnyPermissions([PERMISSIONS.ITEM, PERMISSIONS.STUDENT_ITEM]) && {
        label: 'Inventory',
        type: 'collapsible',
        icon: <Inventory2Outlined sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: '/app/inventory',
            label: 'Inventory',
            type: 'nav-item',
            key: PERMISSIONS.ITEM,
          },
          {
            uri: '/app/inventory-students',
            label: 'Assign Item',
            type: 'nav-item',
            key: PERMISSIONS.STUDENT_ITEM,
          },
        ],
      },

      {
        label: 'Fees',
        type: 'collapsible',
        key: PERMISSIONS.FEE,
        icon: <PointOfSaleOutlined sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: '/app/fees/all',
            label: 'Fee',
            type: 'nav-item',
            key: PERMISSIONS.FEE,
          },
          {
            uri: '/app/fee/items',
            label: 'Fee Cancellation',
            type: 'nav-item',
            key: PERMISSIONS.FEE,
          },
        ],
      },
      {
        uri: '/app/nonExpensePayments',
        label: 'Non Expense Payments',
        type: 'nav-item',
        key: PERMISSIONS.NON_EXPENSE_PAYMENT,
        icon: <PointOfSaleOutlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/app/attendance',
        label: 'Attendance',
        type: 'nav-item',
        key: PERMISSIONS.ATTENDANCE,
        icon: <HowToRegOutlined sx={{ fontSize: 20 }} />,
      },
      {
        label: 'Reports',
        type: 'collapsible',
        key: PERMISSIONS.FEE,
        icon: <SignalCellularAlt sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: '/app/reports',
            label: 'Receipt Diary',
            type: 'nav-item',
            key: PERMISSIONS.FEE,
          },
          {
            uri: '/app/expense-reports',
            label: 'Expense Reports',
            type: 'nav-item',
            key: PERMISSIONS.FEE,
          },
          {
            uri: '/app/non-expense-reports',
            label: 'Non Expense Reports',
            type: 'nav-item',
            key: PERMISSIONS.FEE,
          },
          {
            uri: '/app/defaulter-students',
            label: 'Defaulter Students',
            type: 'nav-item',
            key: PERMISSIONS.FEE,
          },
        ],
      },
    ],
  },
  {
    label: 'Configurations',
    type: 'section',
    children: [
      {
        uri: '/app/configuration/colors',
        label: 'Colors',
        type: 'nav-item',
        key: PERMISSIONS.COLORS,
        icon: <PaletteOutlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/app/configuration/groups/all',
        label: 'Groups',
        type: 'nav-item',
        key: PERMISSIONS.GROUPS,
        icon: <GroupsOutlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/app/configuration/holidays',
        label: 'Holidays',
        type: 'nav-item',
        key: PERMISSIONS.HOLIDAY,
        icon: <BeachAccessOutlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/app/configuration/occupations',
        label: 'Occupations',
        type: 'nav-item',
        key: PERMISSIONS.OCCUPATION,
        icon: <WorkOutline sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/app/configuration/inactivity-reasons',
        label: 'Student Inactivity Reasons',
        type: 'nav-item',
        key: PERMISSIONS.STUDENT_INACTIVITY_REASON,
        icon: <BlockOutlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/app/configuration/task-to-user',
        label: 'Assign tasks to users',
        type: 'nav-item',
        key: PERMISSIONS.TASK_TO_USER,
        icon: <AssignmentIndOutlined sx={{ fontSize: 20 }} />,
      },
      {
        uri: '/app/configuration/villages',
        label: 'Villages',
        type: 'nav-item',
        key: PERMISSIONS.VILLAGE,
        icon: <LocationCityOutlined sx={{ fontSize: 20 }} />,
      },
    ],
  },
];

export default menus;
